<template>
  <div ref="modalContainer"></div>
  <a-card class="content-wrapper">
    <div class="body-content">
      <AnalysisTag></AnalysisTag>
      <a-row type="flex" justify="space-between">
        <a-col :span="15">
          <a-space direction="vertical" style="width: 100%">
            <LeftCarInOut></LeftCarInOut>
            <LeftTransactionRecently></LeftTransactionRecently>
            <LeftAddUsersRecently></LeftAddUsersRecently>
          </a-space>
        </a-col>
        <a-col :span="9">
          <a-space direction="vertical">
            <RightSlotUse></RightSlotUse>
            <RightParkingIncome></RightParkingIncome>
          </a-space>
        </a-col>
      </a-row>
    </div>
  </a-card>
</template>

<script>
import { onMounted } from "vue";
import AnalysisTag from "@/views/Dashboard/components/AnalysisTag";
import RightSlotUse from "@/views/Dashboard/components/RightSlotUse";
import RightParkingIncome from "@/views/Dashboard/components/RightParkingIncome";
import LeftCarInOut from "@/views/Dashboard/components/LeftCarInOut";
import LeftTransactionRecently from "@/views/Dashboard/components/LeftTransactionRecently";
import LeftAddUsersRecently from "@/views/Dashboard/components/LeftAddUsersRecently";
export default {
  name: "index",
  components: {
    AnalysisTag,
    RightSlotUse,
    RightParkingIncome,
    LeftCarInOut,
    LeftTransactionRecently,
    LeftAddUsersRecently,
  },
  setup() {
    onMounted(async () => {});

    return {};
  },
};
</script>

<style scoped></style>
