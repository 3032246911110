<template>
  <a-row type="flex" justify="space-between" align="top">
    <a-col :span="4">
      <a-card hoverable>
        <a-row>
          <a-col :span="6" class="avatar_col">
            <a-image :src="incomeImg" />
          </a-col>
          <a-col :span="18">
            <a-statistic
              title="今日停车总收入"
              :precision="2"
              :value="globalVo.todayAmount"
              ><template #prefix> ￥ </template>
            </a-statistic>
          </a-col>
        </a-row>
        <a-divider></a-divider>
        <a-row>
          <a-col :span="12" class="content_col">停车总收入</a-col>
          <a-col :span="12" class="content_col"
            >￥{{ globalVo.totalAmount }}</a-col
          >
        </a-row>
      </a-card>
    </a-col>
    <a-col :span="4">
      <a-card hoverable>
        <a-row>
          <a-col :span="6" class="avatar_col">
            <a-image :src="userImg" />
          </a-col>
          <a-col :span="18">
            <a-statistic title="今日新增会员" :value="globalVo.todayUsers">
            </a-statistic>
          </a-col>
        </a-row>
        <a-divider></a-divider>
        <a-row>
          <a-col :span="12" class="content_col">会员总数</a-col>
          <a-col :span="12" class="content_col">{{
            globalVo.totalUsers
          }}</a-col>
        </a-row>
      </a-card>
    </a-col>
    <a-col :span="4">
      <a-card hoverable>
        <a-row>
          <a-col :span="6" class="avatar_col">
            <a-image :src="orderImg" />
          </a-col>
          <a-col :span="18">
            <a-statistic title="今日交易订单数" :value="globalVo.todayOrders">
            </a-statistic>
          </a-col>
        </a-row>
        <a-divider></a-divider>
        <a-row>
          <a-col :span="12" class="content_col">订单总数</a-col>
          <a-col :span="12" class="content_col">{{
            globalVo.totalOrders
          }}</a-col>
        </a-row>
      </a-card>
    </a-col>
    <a-col :span="4">
      <a-card hoverable>
        <a-row>
          <a-col :span="6" class="avatar_col">
            <a-image :src="inCarImg" />
          </a-col>
          <a-col :span="18">
            <a-statistic title="在停车辆" :value="globalVo.totalInCars">
            </a-statistic>
          </a-col>
        </a-row>
        <a-divider></a-divider>
        <a-row>
          <a-col :span="12" class="content_col">空闲泊位数</a-col>
          <a-col :span="12" class="content_col">{{
            globalVo.feeSlotNum
          }}</a-col>
        </a-row>
      </a-card>
    </a-col>
    <a-col :span="4">
      <a-card hoverable>
        <a-row>
          <a-col :span="6" class="avatar_col">
            <a-image :src="outCarImg" />
          </a-col>
          <a-col :span="18">
            <a-statistic title="今日离场车次" :value="globalVo.todayOutCars">
            </a-statistic>
          </a-col>
        </a-row>
        <a-divider></a-divider>
        <a-row>
          <a-col :span="12" class="content_col">泊位周转率</a-col>
          <a-col :span="12" class="content_col">{{
            globalVo.slotNumRate
          }}</a-col>
        </a-row>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import useDashboard from "@/hooks/useDashboard";
import { onMounted, ref } from "vue";
import { global } from "@/api/dashboardApi";
import useMessage from "@/hooks/useMessage";
import incomeImg from "@/assets/icons/income.png";
import inCarImg from "@/assets/icons/in_car.png";
import orderImg from "@/assets/icons/order.png";
import outCarImg from "@/assets/icons/out_car.png";
import userImg from "@/assets/icons/user.png";
export default {
  name: "AnalysisTag",
  setup() {
    const { showErr } = useMessage();
    const { globalVo, money } = useDashboard();
    const test = ref("../../../assets/icons/out_car.png");
    onMounted(async () => {
      try {
        let r1 = await global();
        globalVo.totalAmount = r1.data.totalAmount
          ? money(r1.data.totalAmount)
          : 0;
        globalVo.todayAmount = r1.data.todayAmount
          ? money(r1.data.todayAmount)
          : 0;
        globalVo.totalOrders = r1.data.totalOrders ? r1.data.totalOrders : 0;
        globalVo.todayOrders = r1.data.todayOrders ? r1.data.todayOrders : 0;
        globalVo.totalUsers = r1.data.totalUsers ? r1.data.totalUsers : 0;
        globalVo.todayUsers = r1.data.todayUsers ? r1.data.todayUsers : 0;
        globalVo.totalInCars = r1.data.totalInCars ? r1.data.totalInCars : 0;
        globalVo.todayOutCars = r1.data.todayOutCars ? r1.data.todayOutCars : 0;
        globalVo.feeSlotNum = r1.data.feeSlotNum ? r1.data.feeSlotNum : 0;
        globalVo.slotNumRate = r1.data.slotNumRate ? r1.data.slotNumRate : 0;
      } catch (e) {
        showErr(e);
      }
    });

    return {
      globalVo,
      test,
      money,
      incomeImg,
      inCarImg,
      outCarImg,
      orderImg,
      userImg,
    };
  },
};
</script>

<style>
.avatar_col {
  text-align: center;
  padding: 10px;
}

.content_col {
  text-align: center;
}
</style>
